@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply absolute;
}

.tooltip {
  visibility: hidden;
}

.has-tooltip:hover .tooltip {
  @apply visible;
}

.tooltip-label {
  position: relative;
  z-index: 10000;
}

.tooltip-label::before {
  content: attr(class);
  text-align: unset;
  text-indent: -75px;
  white-space: nowrap;
  display: inline-block;
  position: absolute; 
  bottom: 50%;
  background: #000; 
  color: #FFF;
  overflow: hidden;
  opacity:0; 
  transition:0.3s; 
  padding: 4px 4px 4px 4px;
  border-radius: 8px;
  /* max-width: 200%; */
  pointer-events: none;
  z-index: 10000;
}

.tooltip-label:hover::before { opacity: 1; bottom: 100%; z-index: 10000;}

.sync-loader, .sync-loader:before, .sync-loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.sync-loader {
  color: #FFF;
  font-size: 7px;
  position: absolute;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.sync-loader:before,
.sync-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.sync-loader:before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.sync-loader:after {
  left: 1.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 1em 0 -1.3em }
  40% { box-shadow: 0 1em 0 0 }
}
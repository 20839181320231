:root {

    /* Light Mode Variables */
    --primary-draft-fill-colour: rgba(46, 173, 247, 0.5);
    --primary-draft-line-colour: rgba(24, 98, 142);
    --transparent-fill-colour: rgba(46, 173, 247, 0.1);
    --summer-draft-line-colour: rgba(200, 200, 200);
    --high-certainty-colour: rgba(41, 134, 204);
    --primary-max-draft-colour: rgba(11, 71, 18);
    --secondary-max-draft-colour: rgba(19, 120, 31);
    --cursor-colour: rgba(0, 0, 0);
    --cursor-warning-colour: rgba(252, 0, 0, 0.9);
    --grid-line-color: rgba(230, 230, 230);
    --data-label-color: rgba(0, 0, 0);
    --horizontal-line-color: rgba(0, 0, 0);
    --horizontal-line-label-color: rgba(0, 0, 0);
    --constraint-colour-1: rgba(102, 102, 102, 0.7);
    --constraint-colour-2: rgba(24, 98, 142, 0.7);

    /* Dark Mode Variables */
    --cursor-colour-dark: rgba(225, 225, 225);
    --grid-line-color-dark: rgba(230, 230, 230);
    --data-label-color-dark: rgba(225, 225, 225);
    --horizontal-line-color-dark: rgba(200, 200, 200);
    --horizontal-line-label-color-dark: rgba(225, 225, 225);


}

:root.dark {
    --max-draft-line-colour: rgba(255, 98, 142);
}


path[stroke='draft-chart-constraint1-colour'] {
    stroke: var(--constraint-colour-1);
}

path[stroke='draft-chart-constraint2-colour'] {
    stroke: var(--constraint-colour-2);
}

stop[stop-color='draft-chart-primary-draft-fill-colour'] {
    stop-color: var(--primary-draft-fill-colour);
}

stop[stop-color='draft-chart-primary-draft-transparent-colour'] {
    stop-color: var(--transparent-fill-colour);
}

path[stroke='draft-chart-primary-draft-fill-colour'] {
    stroke: var(--primary-draft-line-colour);
}

tspan[style="fill:draft-chart-primary-draft-fill-colour"] {
    fill: var(--primary-draft-fill-colour)
}

tspan[style="fill:draft-chart-primary-draft-line-colour"] {
    fill: var(--primary-draft-line-colour)
}

rect[fill='draft-chart-primary-draft-line-colour'] {
  fill: var(--primary-draft-fill-colour)
}

path[fill='draft-chart-primary-draft-line-colour'] {
  fill: var(--primary-draft-line-colour)
}

path[stroke='draft-chart-cursor-colour'] {
    stroke: var(--cursor-colour)
}

path[fill='draft-chart-cursor-colour'] {
    fill: var(--cursor-colour)
}

path[stroke='draft-chart-cursor-warning-colour'] {
    stroke: var(--cursor-warning-colour)
}

path[fill='draft-chart-cursor-warning-colour'] {
    fill: var(--cursor-warning-colour)
}

path[stroke='draft-chart-cursor-colour-dark'] {
    stroke: var(--cursor-colour-dark)
}

path[fill='draft-chart-cursor-colour-dark'] {
    fill: var(--cursor-colour-dark)
}

path[stroke='draft-chart-grid-line-color-dark'] {
    stroke: var(--grid-line-color-dark)
}

path[stroke='draft-chart-grid-line-color'] {
    stroke: var(--grid-line-color)
}

.data-label-draft-time>text {
    color: var(--data-label-color) !important;
    fill: var(--data-label-color) !important;
}

.data-label-draft-time-dark>text {
    color: var(--data-label-color-dark) !important;
    fill: var(--data-label-color-dark) !important;
}

path[fill='draft-chart-high-certainty-color'] {
    fill: var(--high-certainty-colour)
}

rect[fill='draft-chart-high-certainty-color'] {
    fill: var(--high-certainty-colour)
}

tspan[style="fill:draft-chart-high-certainty-color"] {
    fill: var(--high-certainty-colour)
}

path[stroke='draft-chart-horizontal-line-color'] {
    stroke: var(--horizontal-line-label-color)
}

path[stroke='draft-chart-horizontal-line-color-dark'] {
    stroke: var(--horizontal-line-label-color-dark)
}

.draft-chart-horizontal-line-label {
    color: var(--horizontal-line-label-color);
    fill: var(--horizontal-line-label-color);
}

.draft-chart-horizontal-line-label-dark {
    color: var(--horizontal-line-color-dark);
    fill: var(--horizontal-line-color-dark);
}

.dark-mode > div > div > div .draft-title{
    color: var(--cursor-colour-dark);
}